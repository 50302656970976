@font-face {
  font-family: 'PT-Root-UI';
  font-style: normal;
  src: url(../assets/fonts/PT-Root-UI_Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'PT-Root-UI';
  font-style: normal;
  src: url(../assets/fonts/PT-Root-UI_Bold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'PT-Root-UI';
  font-style: normal;
  src: url(../assets/fonts/PT-Root-UI_Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'PT-Root-UI';
  font-style: normal;
  src: url(../assets/fonts/PT-Root-UI_Medium.ttf) format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Faktum-Bold';
  font-style: normal;
  src: url(../assets/fonts/Faktum-Bold.ttf) format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Faktum-BoldItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-ExtraBold';
  font-style: normal;
  src: url(../assets/fonts/Faktum-ExtraBold.ttf) format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Faktum-ExtraBoldItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 900;
}
@font-face {
  font-family: 'Faktum-ExtraLight';
  font-style: normal;
  src: url(../assets/fonts/Faktum-ExtraLight.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Faktum-ExtraLight';
  font-style: normal;
  src: url(../assets/fonts/Faktum-ExtraLight.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Faktum-ExtraLightItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-Light';
  font-style: normal;
  src: url(../assets/fonts/Faktum-Light.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Faktum-LightItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-Medium';
  font-style: normal;
  src: url(../assets/fonts/Faktum-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-MediumItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum';
  font-style: normal;
  src: url(../assets/fonts/Faktum-Regular.ttf) format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Faktum-RegularItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-RegularItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-SemiBold';
  font-style: normal;
  src: url(../assets/fonts/Faktum-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-SemiboldItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Faktum-Thin';
  font-style: normal;
  src: url(../assets/fonts/Faktum-Thin.ttf) format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Faktum-ThinItalic';
  font-style: italic;
  src: url(../assets/fonts/Faktum-ThinItalic.ttf) format('truetype');
  font-weight: 100;
}
