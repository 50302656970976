@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --blue-100: 15, 33, 80;
    --blue-200: 24, 55, 132;
    --blue-300: 33, 75, 183;
    /* blue-400 */
    --primary: 43, 99, 241; /* #2B63F1 */
    --blue-400: 43, 99, 241; /* #2B63F1 */
    --blue-500: 76, 123, 244;
    --blue-600: 100, 141, 245;
    --blue-700: 125, 159, 247;
    --blue-800: 150, 178, 249;
    --blue-850: 177, 197, 250;
    --blue-900: 218, 228, 253;
    --blue-950: 236, 241, 254;
    --blue-970: 249, 250, 255; /* #F9FAFF */

    --grey-100: 46, 47, 51;
    --grey-200: 77, 79, 85;
    --grey-300: 108, 112, 120;
    --grey-400: 139, 144, 154; /* #8B909A */
    --grey-500: 153, 157, 166;
    --grey-600: 166, 170, 178; /* #A6AAB2 */
    --grey-700: 183, 186, 194;
    --grey-750: 198, 201, 207;
    --grey-800: 212, 215, 220;
    --grey-850: 228, 229, 234; /* #E4E5EA */
    --grey-900: 240, 241, 245;
    --grey-950: 247, 247, 249;

    --red-100: 71, 21, 30;
    --red-200: 118, 35, 49;
    --red-300: 165, 48, 69;
    --red-400: 216, 63, 90;
    --red-500: 223, 95, 118;
    --red-600: 227, 118, 137;
    --red-700: 232, 141, 157;
    --red-800: 236, 162, 175;
    --red-850: 242, 190, 199;
    --red-900: 247, 215, 221;
    --red-950: 253, 240, 243;

    --green-100: 24, 58, 24;
    --green-200: 41, 98, 41;
    --green-300: 57, 138, 57;
    --green-400: 74, 180, 74;
    --green-500: 106, 193, 106;
    --green-600: 128, 202, 128;
    --green-700: 149, 211, 149;
    --green-800: 171, 220, 171;
    --green-850: 198, 231, 198;
    --green-900: 222, 241, 222;
    --green-950: 239, 249, 239;

    --yellow-100: 74, 56, 1;
    --yellow-200: 126, 94, 1;
    --yellow-300: 178, 133, 1;
    --yellow-400: 230, 172, 0;
    --yellow-500: 235, 187, 44;
    --yellow-600: 238, 199, 82;
    --yellow-700: 241, 207, 106;
    --yellow-800: 244, 217, 136;
    --yellow-850: 247, 228, 172;
    --yellow-900: 254, 242, 205;
    --yellow-950: 255, 250, 237;

    --azure-100: 7, 63, 66;
    --azure-200: 11, 107, 110;
    --azure-300: 15, 147, 152;
    --azure-400: 19, 194, 201;
    --azure-500: 60, 205, 210;
    --azure-600: 89, 212, 217;
    --azure-700: 117, 220, 223;
    --azure-800: 150, 228, 231;
    --azure-850: 176, 235, 237;
    --azure-900: 212, 244, 245;
    --azure-950: 241, 252, 252;

    --blue-900-tr: rgba(37, 99, 255, 0.19);
  }

  body {
    @apply text-grey-400 font-normal;
    font-family: 'PT-Root-UI';

    font-size: 14px;
  }

  html {
    background: #d6d6d6;
  }
}

@layer components {
  .text-field {
    @apply text-black w-full border rounded-none px-3 py-2 bg-white border-grey-750 hover:bg-white hover:border-grey-300 focus:border-blue-400 focus-within:bg-white focus-within:border-blue-400  focus:outline-none disabled:bg-grey-900 disabled:border-grey-900 disabled:text-grey-700;
  }
  .text-field::placeholder {
    @apply text-grey-700 hover:text-grey-200;
  }
  label {
    @apply mb-1 w-full;
  }
  .form-control:focus-within label {
    @apply text-blue-400;
  }
  .helper-text {
    @apply text-grey-600;
  }
  .helper-text[data-invalid='true'] {
    @apply text-red-400;
  }
  .text-field[data-invalid='true'] {
    @apply border-red-400;
  }
  .text-field-wrapper {
    @apply relative;
  }
  .text-field-wrapper .input-before {
    @apply absolute left-3 top-[50%] translate-y-[-50%];
  }
  .text-field-wrapper .input-after {
    @apply absolute right-3 top-[50%] translate-y-[-50%];
  }
  .text-field[data-before='true'] {
    @apply pl-10;
  }
  .text-field[data-after='true'] {
    @apply pr-10;
  }

  .btn {
    @apply border-none font-normal no-animation  text-base h-auto min-h-[44px]  grid grid-flow-col items-center gap-x-2 px-3 py-2 rounded-none capitalize;
  }
  .btn.wallet-btn {
    @apply border  border-grey-850  bg-transparent hover:bg-transparent justify-start   items-center w-full h-11 px-2 mb-2 relative;
    border: 1px solid rgb(var(--grey-850));
  }
  .btn.wallet-btn:before {
    @apply absolute right-0;
    border-bottom-color: rgb(var(--primary));
    border-right-color: rgb(var(--primary));
  }

  .btn.primary[disabled] svg {
    @apply fill-white;
  }
  .btn.primary[disabled] {
    @apply text-white;
  }
  .btn.primary[data-color='blue'] {
    @apply bg-blue-400 hover:bg-blue-300 active:bg-blue-200 disabled:bg-blue-900;
  }
  .btn.secondary[data-color='blue'] {
    @apply bg-blue-950 text-blue-400 hover:text-blue-300 hover:bg-blue-900 active:text-blue-200 active:bg-blue-850 disabled:bg-blue-970 disabled:text-blue-800;
  }
  .btn.secondary[data-color='blue'] svg {
    @apply fill-blue-400;
  }
  .btn.secondary[data-color='blue']:hover svg {
    @apply fill-blue-300;
  }
  .btn.secondary[data-color='blue']:active svg {
    @apply fill-blue-200;
  }
  .btn.secondary[data-color='blue']:disabled svg {
    @apply fill-blue-800;
  }
  .btn.tertiary[data-color='blue'] {
    @apply bg-transparent border-solid border border-blue-850 text-blue-400 hover:text-blue-300 hover:border-blue-800 active:border-blue-700 disabled:border-blue-950 disabled:text-blue-800;
  }
  .btn.tertiary[data-color='blue'] svg {
    @apply fill-blue-400;
  }
  .btn.tertiary[data-color='blue']:hover svg {
    @apply fill-blue-300;
  }
  .btn.tertiary[data-color='blue']:active svg {
    @apply fill-blue-200;
  }
  .btn.tertiary[data-color='blue']:disabled svg {
    @apply fill-blue-800;
  }
  .btn.ghost[data-color='blue'] {
    @apply bg-transparent text-blue-400 hover:text-blue-300 hover:bg-blue-400/10 active:text-blue-200 disabled:text-blue-800;
  }
  .btn.ghost[data-color='blue']:active {
    background: var(--blue-900-tr);
  }
  .btn.ghost[data-color='blue'] svg {
    @apply fill-blue-400;
  }
  .btn.ghost[data-color='blue']:hover svg {
    @apply fill-blue-300;
  }
  .btn.ghost[data-color='blue']:active svg {
    @apply fill-blue-200;
  }
  .btn.ghost[data-color='blue']:disabled svg {
    @apply fill-blue-800;
  }
  .btn.ghost.no-paddings[data-color='blue'] {
    @apply p-0 bg-transparent text-blue-400 hover:text-blue-300 active:text-blue-200 disabled:text-blue-800;
  }
  .btn.ghost.no-paddings[data-color='blue'] svg {
    @apply fill-blue-400;
  }
  .btn.ghost.no-paddings[data-color='blue']:hover svg {
    @apply fill-blue-300;
  }
  .btn.ghost.no-paddings[data-color='blue']:active svg {
    @apply fill-blue-200;
  }
  .btn.ghost.no-paddings[data-color='blue']:disabled svg {
    @apply fill-blue-800;
  }

  .btn.primary[data-color='yellow'] {
    @apply bg-yellow-400 hover:bg-yellow-300 active:bg-yellow-200 disabled:bg-yellow-900;
  }
  .btn.secondary[data-color='yellow'] {
    @apply bg-yellow-950 text-yellow-400 hover:text-yellow-300 hover:bg-yellow-900 active:text-yellow-200 active:bg-yellow-850 disabled:bg-yellow-950 disabled:text-yellow-800;
  }
  .btn.secondary[data-color='yellow'] svg {
    @apply fill-yellow-400;
  }
  .btn.secondary[data-color='yellow']:hover svg {
    @apply fill-yellow-300;
  }
  .btn.secondary[data-color='yellow']:active svg {
    @apply fill-yellow-200;
  }
  .btn.secondary[data-color='yellow']:disabled svg {
    @apply fill-yellow-800;
  }
  .btn.tertiary[data-color='yellow'] {
    @apply bg-transparent border-solid border border-yellow-850 text-yellow-400 hover:text-yellow-300 hover:border-yellow-800 active:border-yellow-700 disabled:border-yellow-950 disabled:text-yellow-800;
  }
  .btn.tertiary[data-color='yellow'] svg {
    @apply fill-yellow-400;
  }
  .btn.tertiary[data-color='yellow']:hover svg {
    @apply fill-yellow-300;
  }
  .btn.tertiary[data-color='yellow']:active svg {
    @apply fill-yellow-200;
  }
  .btn.tertiary[data-color='yellow']:disabled svg {
    @apply fill-yellow-800;
  }
  .btn.ghost[data-color='yellow'] {
    @apply bg-transparent text-yellow-400 hover:text-yellow-300 hover:bg-yellow-400/10 active:text-yellow-200 disabled:text-yellow-800;
  }
  .btn.ghost[data-color='yellow']:active {
    background: var(--yellow-900-tr);
  }
  .btn.ghost[data-color='yellow'] svg {
    @apply fill-yellow-400;
  }
  .btn.ghost[data-color='yellow']:hover svg {
    @apply fill-yellow-300;
  }
  .btn.ghost[data-color='yellow']:active svg {
    @apply fill-yellow-200;
  }
  .btn.ghost[data-color='yellow']:disabled svg {
    @apply fill-yellow-800;
  }
  .btn.ghost.no-paddings[data-color='yellow'] {
    @apply p-0 bg-transparent text-yellow-400 hover:text-yellow-300 active:text-yellow-200 disabled:text-yellow-800;
  }
  .btn.ghost.no-paddings[data-color='yellow'] svg {
    @apply fill-yellow-400;
  }
  .btn.ghost.no-paddings[data-color='yellow']:hover svg {
    @apply fill-yellow-300;
  }
  .btn.ghost.no-paddings[data-color='yellow']:active svg {
    @apply fill-yellow-200;
  }
  .btn.ghost.no-paddings[data-color='yellow']:disabled svg {
    @apply fill-yellow-800;
  }
  .btn.primary[data-color='green'] {
    @apply bg-green-400 hover:bg-green-300 active:bg-green-200 disabled:bg-green-900;
  }
  .btn.secondary[data-color='green'] {
    @apply bg-green-950 text-green-400 hover:text-green-300 hover:bg-green-900 active:text-green-200 active:bg-green-850 disabled:bg-green-950 disabled:text-green-800;
  }
  .btn.secondary[data-color='green'] svg {
    @apply fill-green-400;
  }
  .btn.secondary[data-color='green']:hover svg {
    @apply fill-green-300;
  }
  .btn.secondary[data-color='green']:active svg {
    @apply fill-green-200;
  }
  .btn.secondary[data-color='green']:disabled svg {
    @apply fill-green-800;
  }
  .btn.tertiary[data-color='green'] {
    @apply bg-transparent border-solid border border-green-850 text-green-400 hover:text-green-300 hover:border-green-800 active:border-green-700 disabled:border-green-950 disabled:text-green-800;
  }
  .btn.tertiary[data-color='green'] svg {
    @apply fill-green-400;
  }
  .btn.tertiary[data-color='green']:hover svg {
    @apply fill-green-300;
  }
  .btn.tertiary[data-color='green']:active svg {
    @apply fill-green-200;
  }
  .btn.tertiary[data-color='green']:disabled svg {
    @apply fill-green-800;
  }
  .btn.ghost[data-color='green'] {
    @apply bg-transparent text-green-400 hover:text-green-300 hover:bg-green-400/10 active:text-green-200 disabled:text-green-800;
  }
  .btn.ghost[data-color='green']:active {
    background: var(--green-900-tr);
  }
  .btn.ghost[data-color='green'] svg {
    @apply fill-green-400;
  }
  .btn.ghost[data-color='green']:hover svg {
    @apply fill-green-300;
  }
  .btn.ghost[data-color='green']:active svg {
    @apply fill-green-200;
  }
  .btn.ghost[data-color='green']:disabled svg {
    @apply fill-green-800;
  }
  .btn.ghost.no-paddings[data-color='green'] {
    @apply p-0 bg-transparent text-green-400 hover:text-green-300 active:text-green-200 disabled:text-green-800;
  }
  .btn.ghost.no-paddings[data-color='green'] svg {
    @apply fill-green-400;
  }
  .btn.ghost.no-paddings[data-color='green']:hover svg {
    @apply fill-green-300;
  }
  .btn.ghost.no-paddings[data-color='green']:active svg {
    @apply fill-green-200;
  }
  .btn.ghost.no-paddings[data-color='green']:disabled svg {
    @apply fill-green-800;
  }

  .btn.primary[data-color='red'] {
    @apply bg-red-400 hover:bg-red-300 active:bg-red-200 disabled:bg-red-900;
  }
  .btn.secondary[data-color='red'] {
    @apply bg-red-950 text-red-400 hover:text-red-300 hover:bg-red-900 active:text-red-200 active:bg-red-850 disabled:bg-red-950 disabled:text-red-800;
  }
  .btn.secondary[data-color='red'] svg {
    @apply fill-red-400;
  }
  .btn.secondary[data-color='red']:hover svg {
    @apply fill-red-300;
  }
  .btn.secondary[data-color='red']:active svg {
    @apply fill-red-200;
  }
  .btn.secondary[data-color='red']:disabled svg {
    @apply fill-red-800;
  }
  .btn.tertiary[data-color='red'] {
    @apply bg-transparent border-solid border border-red-850 text-red-400 hover:text-red-300 hover:border-red-800 active:border-red-700 disabled:border-red-950 disabled:text-red-800;
  }
  .btn.tertiary[data-color='red'] svg {
    @apply fill-red-400;
  }
  .btn.tertiary[data-color='red']:hover svg {
    @apply fill-red-300;
  }
  .btn.tertiary[data-color='red']:active svg {
    @apply fill-red-200;
  }
  .btn.tertiary[data-color='red']:disabled svg {
    @apply fill-red-800;
  }
  .btn.ghost[data-color='red'] {
    @apply bg-transparent text-red-400 hover:text-red-300 hover:bg-red-400/10 active:text-red-200 disabled:text-red-800;
  }
  .btn.ghost[data-color='red']:active {
    background: var(--red-900-tr);
  }
  .btn.ghost[data-color='red'] svg {
    @apply fill-red-400;
  }
  .btn.ghost[data-color='red']:hover svg {
    @apply fill-red-300;
  }
  .btn.ghost[data-color='red']:active svg {
    @apply fill-red-200;
  }
  .btn.ghost[data-color='red']:disabled svg {
    @apply fill-red-800;
  }
  .btn.ghost.no-paddings[data-color='red'] {
    @apply p-0 bg-transparent text-red-400 hover:text-red-300 active:text-red-200 disabled:text-red-800;
  }
  .btn.ghost.no-paddings[data-color='red'] svg {
    @apply fill-red-400;
  }
  .btn.ghost.no-paddings[data-color='red']:hover svg {
    @apply fill-red-300;
  }
  .btn.ghost.no-paddings[data-color='red']:active svg {
    @apply fill-red-200;
  }
  .btn.ghost.no-paddings[data-color='red']:disabled svg {
    @apply fill-red-800;
  }

  .sale-type-label img {
    display: none;
  }
  .dropdown-list .dropdown-item.filter {
    @apply p-0;
  }
  .dropdown-item.filter label {
    @apply grid grid-flow-col py-2 px-3 justify-between items-center hover:cursor-pointer;
  }
  .sale-type-checkbox:checked + label {
    @apply text-blue-400;
  }
  .sale-type-checkbox:checked + label img {
    @apply block;
  }
  .btn-with-arrow {
    @apply relative;
  }
  .btn-with-arrow:after {
    @apply absolute right-4 top-[50%] transform translate-y-[-50%];
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-bottom: 5px solid black;
  }
  .btn-with-arrow[data-open='true']:after {
    @apply rotate-180 transform;
  }

  .title {
    @apply text-xl text-black text-ellipsis whitespace-nowrap overflow-hidden;
    font-weight: 600;
    font-family: 'Faktum-Bold';
  }

  .overflow-text {
    @apply text-ellipsis whitespace-nowrap overflow-hidden w-full;
  }

  .modal {
    @apply overflow-y-auto;
  }
  .modal-content {
    @apply w-full h-full px-4 py-6 bg-white md:p-6 md:w-auto md:h-auto;
  }
  .modal-description {
    @apply mb-4;
  }
  .modal-title {
    @apply mb-4 pr-8;
  }
  .modal-actions {
    @apply grid grid-cols-2 gap-x-2;
  }

  .listbox-btn {
    @apply relative text-left text-black w-full border rounded-none px-3 pr-8 py-2 bg-white border-grey-750 hover:bg-white hover:border-grey-300 focus:border-blue-400 focus-within:bg-white focus-within:border-blue-400  focus:outline-none disabled:bg-grey-900 disabled:border-grey-900 disabled:text-grey-700;
  }
  .listbox-btn:after {
    @apply block w-0 h-0 absolute right-4 translate-y-[-50%] top-[50%];
    content: ' ';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgb(var(--grey-700));
  }

  .listbox-option {
    @apply text-black  relative cursor-default select-none py-2 px-3 pr-10 hover:text-blue-400 hover:bg-grey-850;
  }
  .listbox-option[data-active='true'] {
    @apply text-blue-400 hover:bg-grey-850;
  }

  .nft-card {
    @apply grid p-3 border border-grey-850 content-between;
  }
  .nft-card .image {
    @apply h-[300px] sm:h-[358px] md:h-[246px] lg:h-[276px] xl:h-[251px] overflow-hidden relative;
  }
  .nft-card-overlay {
    @apply invisible z-10;
    background: rgba(0, 0, 0, 0.2);
  }

  .nft-card:hover .nft-card-overlay {
    @apply visible;
  }
  .nft-card .image > * {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .nft-card .auction-timer {
    @apply grid grid-flow-col justify-between items-center text-white p-3;
    background: rgba(57, 63, 87, 0.28);
    backdrop-filter: blur(32px);
  }

  .dropdown-list {
    @apply absolute p-3 bg-white border border-grey-950 outline-none z-10;
    backdrop-filter: blue(64px);
    box-shadow: 0px 8px 16px rgba(43, 51, 56, 0.08);
  }

  .dropdown-list .dropdown-item {
    @apply p-2 select-none hover:cursor-pointer;
  }
  .dropdown-list .dropdown-item a {
    @apply block;
  }

  .dropdown-list .dropdown-item:hover {
    background: rgba(43, 99, 241, 0.08);
  }

  .nft-card .dropdown-btn {
    @apply w-10 h-10 grid items-center justify-center;
  }
  .dropdown-btn:hover {
    background: rgba(79, 89, 129, 0.09);
  }

  .tab-btn {
    @apply p-3 border-b border-grey-800 relative outline-none;
  }
  .tab-btn.active:after {
    @apply block absolute inset-x-0 bottom-0 bg-blue-400 h-[1px];
    content: ' ';
  }
  .tab-btn.active {
    @apply text-blue-400 border-blue-400;
  }

  .explore-link {
    @apply p-3 text-center w-full text-black select-none hover:cursor-pointer;
    background: rgba(79, 89, 129, 0.05);
  }
  .explore-link:hover {
    background: rgba(79, 89, 129, 0.09);
  }

  .activity-item {
    @apply border-b border-b-transparent hover:bg-blue-970 hover:border-b-blue-400;
  }

  .activity-label {
    @apply min-w-[55px] max-w-max capitalize grid grid-flow-col gap-x-1 justify-center items-center justify-start py-1 px-2 rounded-[27px] text-xs select-none;
  }
  .activity-label > img {
    @apply w-3 h-3;
  }
  .activity-label[data-event^='y-'] {
    @apply bg-yellow-900 text-yellow-300;
  }
  .activity-label[data-event^='b-'] {
    @apply bg-blue-900 text-blue-300;
  }
  .activity-label[data-event^='g-'] {
    @apply bg-green-900 text-green-300;
  }
  .activity-label[data-event^='gr-'] {
    @apply bg-grey-900 text-grey-300;
  }

  .collection-item {
    @apply border border-grey-850 flex flex-col;
  }
  .collection-item .wallpaper {
    @apply h-[260px] overflow-hidden;
  }
  .collection-item .wallpaper > .img {
    @apply w-full h-full object-cover;
  }
  .collection-item .logo {
    @apply absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 w-[82px] h-[82px] rounded-[50%] overflow-hidden border-4 border-white;
  }
  .collection-item .logo > .img {
    @apply w-full h-full object-cover;
  }
  .collection-item .info {
    @apply grid grid-cols-3 text-center items-center p-2 bg-grey-950 gap-x-2;
  }
  .collection-item .info-title {
    @apply text-sm mb-1;
  }
  .collection-item .info-label {
    @apply font-bold text-black;
  }
  .collection-item .text-container {
    @apply text-center px-3 mt-12;
  }

  .stats-table {
    @apply lg:border border-grey-850;
  }
  .stats-table .stats-cell {
    @apply flex text-black text-sm font-normal px-3 basis-0 lg:block;
  }
  .stats-table .stats-row {
    @apply flex flex-col gap-2 py-4 border-b border-b-grey-850 lg:gap-0 lg:flex-row lg:items-center lg:p-3 last:border-b-0;
  }
  .stats-table header.stats-row {
    @apply hidden lg:flex bg-grey-950;
  }
  .stats-table header.stats-row .stats-cell {
    @apply text-grey-400 font-medium;
  }
  .stats-table .stats-row .stats-cell {
    @apply grow first:grow-0 lg:first:basis-16;
  }
  .stats-table .stats-label {
    @apply flex-1 text-grey-600 text-sm lg:hidden;
  }

  .footer-list-header {
    @apply text-black text-base leading-5 font-bold;
  }
  .footer-link {
    @apply text-grey-400 text-sm font-normal;
  }
  .footer-icon-link {
    @apply flex items-center justify-center w-10 h-10;
  }

  .loader {
    @apply w-[30px] h-[30px] rounded-[50%] mx-auto;
    border: 3px solid rgba(var(--primary));
    border-top: 3px solid rgba(41, 101, 255, 0.5);
    animation: spin 2s linear infinite;
  }

  .base-toast {
    @apply relative gap-x-4 ml-5  grid grid-flow-col
    items-start p-4 pr-[30px] rounded-none border-l-4 border-transparent hover:cursor-default
    justify-start;
    margin: 0;
  }

  .base-toast[data-variant='info'] {
    @apply border-blue-400;
    backdrop-filter: blur(120px);
    background: linear-gradient(
      90deg,
      #f0f1f5 0%,
      rgba(240, 241, 245, 1) 51.61%
    );
  }
  .base-toast[data-variant='success'] {
    @apply border-green-400;
    backdrop-filter: blur(120px);
    background: linear-gradient(
      90deg,
      #def1de 0%,
      rgba(222, 241, 222, 0.4) 51.61%
    );
  }
  .base-toast[data-variant='error'] {
    @apply border-red-400;
    backdrop-filter: blur(120px);
    background: linear-gradient(
      90deg,
      #f7d7dd 0%,
      rgba(247, 215, 221, 0.4) 51.61%
    );
  }
  .base-toast[data-variant='warn'] {
    @apply border-yellow-400;
    backdrop-filter: blur(120px);
    background: linear-gradient(
      90deg,
      #fef2cd 0%,
      rgba(254, 242, 205, 0.4) 51.61%
    );
  }
  .base-toast .toast-icon {
    @apply w-[22px] h-[22px];
  }
  .base-toast .toast-icon > img {
    @apply w-full;
  }
  .base-toast .title {
    @apply text-lg mb-1;
    font-family: 'Faktum-Bold';
  }

  .base-toast .message {
    @apply text-black mb-4;
  }
  .base-toast .actions {
    @apply grid items-center grid-flow-col gap-x-4;
  }

  .base-checkbox div {
    @apply border w-4 h-4 border-grey-700 select-none grid justify-center items-center hover:cursor-pointer;
  }
  .base-checkbox input {
    @apply absolute hidden;
  }
  .base-checkbox input:checked ~ div {
    @apply bg-blue-400 border-blue-400;
  }
  .responsive-grid {
    @apply grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5;
  }

  .guide .guide-term {
    @apply title whitespace-normal flex items-center py-3 border-t cursor-pointer first:border-0 hover:text-blue-300;
  }
  .guide .guide-term._expanded .icon {
    @apply rotate-0;
  }
  .guide .guide-term._expanded + .guide-desc {
    @apply block;
  }
  .guide .guide-term .icon {
    @apply rotate-45 ml-auto transition-transform;
  }
  .guide .guide-desc {
    @apply hidden my-4;
  }
  .guide .guide-desc h2 {
    @apply text-lg text-black mt-4 mb-2;
  }
  .guide .guide-desc p {
    @apply mb-2;
  }
  .guide .guide-desc a {
    @apply text-blue-400;
  }
}
.Toastify__toast-container {
  @apply w-[400px];
  padding: 0;
  z-index: 9999;
  transform: translate3d(0, 0, 9999px);
  position: fixed;
  box-sizing: border-box;
}
.Toastify__toast {
  @apply p-0 m-0 rounded-none;
  font-family: 'PT-Root-UI';
}
.Toastify__toast-body {
  @apply p-0;
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}
.img-pixelated {
  image-rendering: pixelated;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
